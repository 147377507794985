import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import { getFile, uploadPlayerImage } from "../../services/storage";

//import { positionMap } from '../../helpers/validation';
import { teams } from "../../helpers/validation";
import {
    parseFlexibleDate,
    calculateAge,
    formatBirthDate,
    isBirthday,
} from "../../helpers/dates";
import { validateImageExtension } from "../../helpers/structureData";

import defaultPic from "../../images/playerthumbnail.png";
import defaultPicPitcher from "../../images/pitcherthumbnail.png";

import "./PlayerHero.scss";

const getTeam = (teamIdentifier) => {
    return Object.entries(teams).filter((entry) => entry[1] === teamIdentifier)[0][0];
};

const PlayerHero = ({ playerData }) => {
    const user = useSelector((state) => state.user);

    const {
        key,
        name,
        number,
        bats,
        pos,
        weight,
        height,
        bornday,
        bornLocation,
        comment,
        Transactions,
        alias,
        died,
        DraftedRound,
        DraftedPick,
        DraftedTeam,
        DraftedYear,
    } = playerData;

    const getDisplayBornDayAndAge = (bornDateString, died) => {
        const playerStillAlive = died === "0000-00-00";
        const bornDate = parseFlexibleDate(bornDateString);
        if (!bornDate) return bornDateString;
        const age = calculateAge(bornDate);
        return `${formatBirthDate(bornDate)}${playerStillAlive ? ` (${age} años)` : ""}`;
    };

    const shouldDisplayBirthday = (bornDateString, died) => {
        const playerStillAlive = died === "0000-00-00";
        const bornDate = parseFlexibleDate(bornDateString);
        if (!bornDate || !playerStillAlive) return false;
        return isBirthday(bornDate);
    };

    const thro = playerData.throw;
    const prizes = playerData.prizes;
    const draftedText =
        DraftedRound !== "-" &&
        DraftedPick !== "-" &&
        DraftedTeam !== "-" &&
        DraftedYear !== "-"
            ? `Drafteado por ${getTeam(
                  DraftedTeam
              )} en la ronda ${DraftedRound} (pick #${DraftedPick}) del draft de novatos de ${DraftedYear}.`
            : false;

    const [playerPic, setPlayerPic] = useState("");
    const [fileUploadPercent, setFileUploadPercent] = useState(0);

    useEffect(() => {
        const getPlayerPic = async () => {
            try {
                const result = await getFile("players", `thumb_${key}.jpeg`);
                if (result) {
                    setPlayerPic(result);
                }
            } catch (e) {
                setPlayerPic(pos === "P" ? defaultPicPitcher : defaultPic);
            }
        };
        getPlayerPic();
    }, [key, pos]);

    const handleUploadImage = async (event) => {
        const file = event.target.files[0];
        if (file && validateImageExtension(file.name.toLowerCase())) {
            Object.defineProperty(file, "name", {
                writable: true,
                value: `thumb_${key}.jpeg`,
            });
            const downloadUrl = await uploadPlayerImage(file, setFileUploadPercent);
            if (downloadUrl) {
                setFileUploadPercent(100);
            }
        }
    };

    return (
        <div className="playerHero">
            <div className="playerHeroContent">
                <h2>{`${name}`}</h2>
                <h3>{`#${number}/${pos}`}</h3>
                <div>
                    <img
                        src={
                            playerPic
                                ? playerPic
                                : pos === "P"
                                ? defaultPicPitcher
                                : defaultPic
                        }
                        alt={`${name}`}
                    />
                    <div className="dataBlock">
                        {alias !== "-" && (
                            <div>
                                Alias: <span>{alias}</span>
                            </div>
                        )}
                        <div>
                            Batea: <span>{bats}</span>
                        </div>
                        <div>
                            Tira: <span>{thro}</span>
                        </div>
                        <div>
                            Peso: <span>{weight}</span>
                        </div>
                        <div>
                            Altura: <span>{height}</span>
                        </div>
                    </div>
                    <div className="dataBlock datesBlock">
                        <div>
                            Fecha de Nacimiento: <br></br>
                            <span>{getDisplayBornDayAndAge(bornday, died)}</span>
                            {shouldDisplayBirthday(bornday, died) && (
                                <div className="birthday">
                                    Feliz cumpleaños!{" "}
                                    <span role="img" aria-label="birthday-bake">
                                        🎂
                                    </span>
                                </div>
                            )}
                        </div>
                        <div>
                            Lugar de Nacimiento: <br></br>
                            <span>{bornLocation}</span>
                        </div>
                        {died !== "0000-00-00" && (
                            <div>
                                Fallecimiento: <br></br>
                                <span>{died}</span>
                            </div>
                        )}
                    </div>
                    <div className="dataBlock">
                        {prizes && prizes.length && <h5>Premios:</h5>}
                        {prizes && prizes.length && (
                            <ul className="prizes">
                                {prizes.map((prize, index) => {
                                    return (
                                        <li key={Object.keys(prize)[0] + index}>
                                            {`${Object.keys(prize)[0]} ${
                                                prize[Object.keys(prize)[0]]
                                            }`}
                                        </li>
                                    );
                                })}
                            </ul>
                        )}
                    </div>
                </div>
                {user.role === "admin" || user.role === "photo-editor" ? (
                    <div>
                        <input type="file" onChange={handleUploadImage} />
                        <span className="displayPercent">{fileUploadPercent}</span>
                    </div>
                ) : (
                    <></>
                )}

                {draftedText && (
                    <div className="commentBlock">
                        <p className="comment">
                            <span>Draft:</span> {draftedText}
                        </p>
                    </div>
                )}
                {Transactions !== "-" && (
                    <div className="commentBlock">
                        <p className="comment">
                            <span>Transacciones:</span> {Transactions}
                        </p>
                    </div>
                )}
                <div className="commentBlock">
                    <p className="comment">
                        <span>Notas:</span> {comment}
                    </p>
                </div>
            </div>
        </div>
    );
};

export default PlayerHero;
