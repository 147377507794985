//functions that receive CSV files and transform them into JSON format for uploading them into firebase.
import {
    sampleAllStats,
    sampleGlobalStats,
    samplePrizes,
    sampleYearMetrics,
    sampleYearValidation,
    samplePlayerValidation,
    teams,
    phases,
    samplePlayers,
} from "./validation";

//checks if two arrays have the same values
const validateEquality = (inputArray, expectedArray) => {
    if (
        inputArray.every((key, index) => {
            return key === expectedArray[index];
        })
    ) {
        return true;
    } else {
        return false;
    }
};

const validateDataColumn = (lines, colNum, test) => {
    const testedLines = lines.map((line) => {
        return test(line.split(",")[colNum]);
    });
    return testedLines.every((result) => result === true);
};

const testBlank = (string) => {
    return string !== "";
};

const testYear = (string) => {
    if ((string.length === 2 || string.length === 1) && !isNaN(parseInt(string))) {
        return true;
    } else {
        return false;
    }
};

const testPhase = (string) => {
    return Object.values(phases).includes(string) ? true : false;
};

const testTeam = (string) => {
    return Object.values(teams).includes(string) ? true : false;
};

//USED FOR allstats

const buildStats = (csvString) => {
    const lines = csvString.split("\n");
    const years = {};
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    const contentLines = lines.slice(1, lines.length);

    //security check
    if (headers.length !== 63) {
        return "Error: Mismatch between expected and actual number of columns (63)";
    }
    if (validateEquality(headers, Object.keys(sampleAllStats)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }
    if (validateDataColumn(contentLines, 1, testBlank) === false) {
        return "Security Check: Some items are blank";
    }
    if (validateDataColumn(contentLines, 1, testYear) === false) {
        console.log(lines.slice(1, lines.length));
        return "Security Check: Error while evaluating Year (column 2)";
    }
    if (validateDataColumn(contentLines, 4, testPhase) === false) {
        return "Security Check: Error while evaluating Phase (column 5)";
    }
    if (validateDataColumn(contentLines, 8, testTeam) === false) {
        return "Security Check: Error while evaluating Team (column 9)";
    }

    //populateYears
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        years[currentLine[1]] = {};
    }
    //populatePhases
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        years[currentLine[1]][currentLine[4]] = {};
    }
    //populateTeams
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        years[currentLine[1]][currentLine[4]][currentLine[8]] = {};
    }
    //write documents in corresponding year -> season -> team
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
            //adds data on row
            obj[headers[j]] = currentLine[j];
        }
        years[currentLine[1]][currentLine[4]][currentLine[8]][currentLine[5]] = obj;
    }

    return years;
};

//USED for PLAYERS
const buildPlayers = (csvString) => {
    const lines = csvString.split("\n");

    const result = [];

    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });

    const contentLines = lines.slice(1, lines.length);

    //security check
    if (headers.length !== 22) {
        return "Error: Mismatch between expected and actual number of columns (22)";
    }

    if (validateEquality(headers, Object.keys(samplePlayers)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }
    if (validateDataColumn(contentLines, 1, testBlank) === false) {
        return "Security Check: Some items are blank";
    }

    for (let i = 1; i < lines.length; i++) {
        const obj = {};

        const currentLine = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
        }

        result.push(obj);
    }
    return { data: result, headers: headers };
};

const csvToJSON = (csvString) => {
    const lines = csvString.split("\n");

    const result = [];

    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });

    for (let i = 1; i < lines.length; i++) {
        const obj = {};

        const currentLine = lines[i].split(",");

        for (let j = 0; j < headers.length; j++) {
            obj[headers[j]] = currentLine[j];
        }

        result.push(obj);
    }
    return { data: result, headers: headers };
};

//USED For GLOBAL INFO:
const buildGlobalInfo = (csvString) => {
    const lines = csvString.split("\n");
    const years = {};
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });

    if (headers.length !== 17) {
        return "Error: Mismatch between expected and actual number of columns";
    }
    if (validateEquality(headers, Object.keys(sampleGlobalStats)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }

    //populateYears
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        years[currentLine[0]] = {};
    }

    //write documents in corresponding year
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
            //adds data on row
            obj[headers[j]] = currentLine[j];
        }
        years[currentLine[0]][
            `${currentLine[0]}${currentLine[2]}${currentLine[3]}`
        ] = obj;
    }

    return years;
};

//for prizes
const csvToPrizeMap = (csvString) => {
    const lines = csvString.split("\n");
    //security
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    if (headers.length !== 3) {
        return "Error: Mismatch between expected and actual number of columns";
    }
    if (validateEquality(headers, Object.keys(samplePrizes)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }

    const result = {};
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        result[currentLine[0]] = [];
    }
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        const obj = {};
        obj[currentLine[1]] = currentLine[2];
        result[currentLine[0]].push(obj);
    }
    return result;
};

//CONVERTING CSV TO OBJECTS for STRINGS on FIREBASE

//for year metrics one line per tournament
const csvToYearMetrics = (csvString) => {
    const lines = csvString.split("\n");
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    if (headers.length !== 14) {
        return "Error: Mismatch between expected and actual number of columns";
    }
    if (validateEquality(headers, Object.keys(sampleYearMetrics)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }
    const result = {};
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        const obj = {};
        for (let j = 1; j < currentLine.length; j++) {
            obj[headers[j]] = currentLine[j].trim();
        }
        result[currentLine[0]] = obj;
    }
    return result;
};

//for year validation
const csvToMap = (csvString) => {
    const lines = csvString.split("\n");
    //security
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    if (headers.length !== 2) {
        return "Error: Mismatch between expected and actual number of columns";
    }
    if (validateEquality(headers, Object.keys(sampleYearValidation)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }

    const result = {};
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        result[currentLine[0]] = currentLine[1].trim();
    }
    return result;
};
//for players validation
const csvToPlayerMap = (csvString) => {
    const lines = csvString.split("\n");
    //security
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    if (headers.length !== 4) {
        return "Error: Mismatch between expected and actual number of columns";
    }
    if (validateEquality(headers, Object.keys(samplePlayerValidation)) === false) {
        return "Error: The key names provided do not match the expected key names.";
    }

    const result = {};
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        //result[currentLine[1]] = {key: currentLine[0].trim(), debut: currentLine[3].trim(), alias: currentLine[2].trim()}
        result[
            `${currentLine[1]}${
                currentLine[2].trim() !== "-" ? ` - (${currentLine[2].trim()})` : ""
            } - ${currentLine[3].trim()}`
        ] = {
            key: currentLine[0].trim(),
            debut: currentLine[3].trim(),
            alias: currentLine[2].trim(),
        };
    }
    return result;
};

const csvToPayDatesMap = (csvString) => {
    const lines = csvString.split("\n");
    //security
    const headers = lines[0].split(",").map((header) => {
        return header.trim();
    });
    if (headers.length !== 2) {
        return "Error: Mismatch between expected and actual number of columns";
    }

    const result = {};
    for (let i = 1; i < lines.length; i++) {
        const currentLine = lines[i].split(",");
        result[currentLine[0]] = currentLine[1];
    }
    return result;
};

export {
    csvToJSON,
    buildPlayers,
    buildStats,
    csvToMap,
    buildGlobalInfo,
    csvToPlayerMap,
    csvToYearMetrics,
    csvToPrizeMap,
    validateEquality,
    csvToPayDatesMap,
};
