export function parseFlexibleDate(dateString) {
    // Handle empty or invalid inputs
    if (!dateString || dateString.trim() === "-") return null;

    // Check for YYYY-MM-DD format first
    const isoDateMatch = dateString.match(/^(\d{4})-(\d{2})-(\d{2})$/);
    if (isoDateMatch) {
        return new Date(
            parseInt(isoDateMatch[1], 10),
            parseInt(isoDateMatch[2], 10) - 1,
            parseInt(isoDateMatch[3], 10)
        );
    }

    // Remove any comments in parentheses
    const cleanedString = dateString.replace(/\s*\(.*\)/, "").trim();

    // Predefined month names mapping
    const monthNames = {
        jan: 0,
        january: 0,
        feb: 1,
        february: 1,
        mar: 2,
        march: 2,
        apr: 3,
        april: 3,
        may: 4,
        jun: 5,
        june: 5,
        jul: 6,
        july: 6,
        aug: 7,
        august: 7,
        sep: 8,
        september: 8,
        oct: 9,
        october: 9,
        nov: 10,
        november: 10,
        dec: 11,
        december: 11,
    };

    // Try parsing full formats first
    const fullFormatRegex = /^(\d{1,2})-([a-zA-Z]{3})-(\d{4})$/i;
    const fullMatch = cleanedString.match(fullFormatRegex);
    if (fullMatch) {
        const day = parseInt(fullMatch[1], 10);
        const month = monthNames[fullMatch[2].toLowerCase()];
        const year = parseInt(fullMatch[3], 10);
        return new Date(year, month, day);
    }

    return null;
}

export function calculateAge(birthdatDate) {
    // Calculate age
    const today = new Date();
    let age = today.getFullYear() - birthdatDate.getFullYear();

    // Adjust age if birthday hasn't occurred this year
    const monthDiff = today.getMonth() - birthdatDate.getMonth();
    const dayDiff = today.getDate() - birthdatDate.getDate();

    if (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)) {
        age--;
    }

    return age;
}

// Returns date in YYYY-written month-DD format
export function formatBirthDate(date) {
    if (!date) return date;

    const months = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];

    const formattedDate = `${date.getDate()}-${
        months[date.getMonth()]
    }-${date.getFullYear()}`;
    return formattedDate;
}

export function isBirthday(birthdatDate) {
    const today = new Date();
    const isSameMonth = today.getMonth() === birthdatDate.getMonth();
    const isSameDay = today.getDate() === birthdatDate.getDate();
    return isSameMonth && isSameDay;
}
